<template>
  <footer class="d-flex pt-5">
    <v-row class="d-flex justify-center">
      <v-col cols="8" class="d-flex align-end">
        <v-col cols="4">
          <!-- href="https://nitrosbase.ru/"  -->
          <a 
            href="https://nitrosdata.com/" 
            target="_blank" 
            rel="noopener noreferrer"                
          >
            <v-img
              width="200"
              height="50"           
              src="../../assets/NitrosData.png"
            ></v-img>
          </a>
          <div class="text-body-2"> 
            +7(495)101-43-24
          </div>
        </v-col>
        <v-col cols="4" class="d-flex justify-center align-end">
          <a href="https://nitrosdata.com/" target="_blank"> 
            <p class="text-body-2 acolor">© 2017-2025 NitrosData</p>
          </a>
        </v-col>
        <v-col cols="4" class="d-flex justify-end">
          <div class="d-flex flex-column align-center">
            <a 
              href="https://sk.ru/" 
              target="_blank" 
              rel="noopener noreferrer"       
            >
              <v-img
                v-if="lang=='en'"
                width="60"
                height="50"           
                src="../../assets/Sk__Resident.png"
              ></v-img>
              <v-img
                v-if="lang=='ru'"
                width="60"
                height="50"           
                src="../../assets/Sk__Resident_ru.png"
              ></v-img>
            </a>
            <div class="d-flex flex-column">
              <!-- <p  class="text-center" style="font-size: 12px">Деятельность осуществляется при грантовой поддержке Фонда "Сколково"</p> -->
              <a href="mailto:support@nitrosbase.com" class="text-body-2 text-center acolor ">
                support@nitrosbase.com
              </a>
            </div>

          </div>
        </v-col>
      </v-col>
    </v-row>
    <CoockieBar />
  </footer>
</template>

<script>
import "./FooterComp.scss";
import CoockieBar from "../Coockie/CoockieBar.vue"
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "FooterComp",
  components: {
    CoockieBar
  },
  computed: {
    ...mapGetters(['CURENT_LANG']),
    ...mapState(['lang']),
  },
}
</script>

<style>
.acolor {
  color: black;
}
</style>